<style>
.logo-container {
	height: 64px;
	width: 64px;
}

.logo-circle {
	overflow: hidden;
	position: relative;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-weight: 500;
	height: 100%;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 100%;
	background-color: #32394e;
	border-radius: 50%;
}
</style>
<script>
import {VclCode} from 'vue-content-loading';

import {AccountStatus} from '@/enums';
import CopyableText from "@/components/CopyableText";
import {mapGetters} from "vuex";

export default {
	props: ['cftools_id', 'restricted'],
	components: {
		// eslint-disable-next-line vue/no-unused-components
		CopyableText,
		VclCode
	},
	computed: {
		...mapGetters(['getDTLocale']),
	},
	methods: {
		handleError: function (error) {
			console.log(`[ERROR] ${error}`);
			this.error = true;
		},
		getData: function () {
			fetch(process.env.VUE_APP_ROOT_API + `v1/profile/${this.cftools_id}/publisher-services/easyanticheat/ban-status`, {credentials: 'include'})
					.then(response => {
						if (response.ok) {
							return response.json();
						} else {
							throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
						}
					})
					.then(data => {
						this.ready = true;
						this.banned = data.banned;
						this.records = data.records;
					})
					.catch(error => {
						this.handleError(error);
					});
		}
	},
	mounted() {
		if(!this.restricted) {
			this.getData();
		}
	},
	data() {
		return {
			AccountStatus: AccountStatus,
			ready: false,
			error: false,
			banned: false,
			records: null
		}
	}
};
</script>

<template>
	<div class="card" :class="{'border border-danger': error}">
		<div class="card-body d-flex align-items-center" style="height: 8rem;">
			<template v-if="ready">
				<div class="media">
					<div class="mini-stat-icon avatar-sm align-self-center logo-container">
              <span class="logo-circle">
                <img src="/assets/eac.png" alt="easy anti cheat logo" class="logo-img" height="32px" width="32px">
              </span>
					</div>
					<div class="media-body">
						<div class="ml-3 mt-1">
							<p class="text-muted font-weight-medium">
								{{ $t('profile.eac.title') }}
								<i class="fad fa-info-circle ml-1" v-b-tooltip.hover :title="$t('profile.eac.tooltip')"/>
							</p>
							<h5 class="mb-0">
								<template v-if="banned">
									<i class="far fa-shield-alt text-danger"></i>
									<span class="text-danger">
										{{ $t('profile.eac.status.bad_standing') }}
										<span class="text-muted small">
											{{ $t('profile.eac.status.ban_count', {ban_count: records.length}) }}
										</span>
									</span>
									<div class="row ml-0 mt-2">
										<div class="d-block img-thumbnail mr-2" :class="{
											'border-danger': record.status === 'verified',
											'border-warning': record.status === 'unverified'
										}" v-for="record in records" :key="record.id">
											<img
													alt="game title logo"
													class=""
													:src="'/' +gameIcon(record.game)"
													height="24"
													width="24"
													v-b-tooltip.hover
													:title="`${record.status} - ` + $d(parseDate(record.date), 'datetime', getDTLocale())"
											>
											<span class="text-code m-0 p-0">
												{{ record.id.trim() }}
											</span>
										</div>
									</div>
								</template>
								<template v-else>
									<i class="far fa-shield-alt text-success"></i>
									{{ $t('profile.eac.status.good_standing') }}
								</template>
							</h5>
						</div>
					</div>
				</div>
			</template>
			<template v-else-if="error">
				<div class="media p-2">
					<div class="media-body bg-transparent border-danger">
						<h5 class="my-0 text-danger">
							<i class="far fa-exclamation-circle mr-1"></i>
							{{ $t('error.server.generic.title') }}
						</h5>
						<div class="pt-0 mt-1">
							<h5 class="card-title mt-0"> {{ $t('error.server.generic.component') }}</h5>
						</div>
					</div>
				</div>
			</template>
			<template v-else-if="restricted">
				<div class="media">
					<div class="mini-stat-icon avatar-sm align-self-center logo-container">
            <span class="logo-circle">
              <img src="/assets/eac.png" alt="easy anti cheat logo" class="logo-img" height="32px" width="32px">
            </span>
					</div>
					<div class="media-body blur-content">
						<div class="ml-3 mt-1">
							<p class="text-muted font-weight-medium">
								{{ $t('profile.eac.title') }}
							</p>
							<h5 class="mb-0">
								<i class="far fa-shield-alt"></i>
								Lorem ipsum dolor
							</h5>
						</div>
					</div>
					<div class="blur-content-text h6 text-danger shadow-lg" >
						{{ $t('profile.free_limited') }}
					</div>
				</div>
			</template>
			<template v-else>
				<VclCode style="height: 4rem;" :speed="1" primary="#FFFFFF" secondary="#bdbdbd"></VclCode>
			</template>
		</div>
	</div>
</template>
