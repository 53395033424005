<style>

</style>
<script>
import { VclBulletList, VclFacebook, VclCode, VclList } from 'vue-content-loading';

import {AccountStatus} from '@/enums';
import CopyableText from "@/components/CopyableText";
import {mapGetters} from "vuex";
import EACStatus from "@/components/lazy/profile/components/servicesbar/EACStatus.vue";

export default {
  props: ['cftools_id', 'privacyMode'],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CopyableText,
    VclFacebook,
    VclCode,
    VclList,
    VclBulletList,
    SteamPersona: () => import('@/components/lazy/profile/desktop/components/SteamPersona'),
	  BattlEyeStatus: () => import('@/components/lazy/profile/components/servicesbar/BattlEyeStatus.vue'),
	  EACStatus: () => import('@/components/lazy/profile/components/servicesbar/EACStatus.vue'),
    ProfileActivities: () => import('@/components/lazy/profile/desktop/components/ProfileActivitiesShortlist'),
  },
  computed: {
    ...mapGetters(['getDTLocale','getUILanguage','getSubscriberStatus','getServers','getMemberships']),
  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + `v1/profile/${this.cftools_id}/overview`, {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.ready = true;
          this.restrictedGeneral = false; // data.features.player_intelligence;
          this.data = data;
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    overridePersona(personaName, avatar, error = false) {
      this.$parent.$emit('overridePersona', personaName, avatar, error);
    },
    timeTrick(seconds) {
      return new Date(Date.now() - 1000 * seconds);
    }
  },
  mounted() {
    this.shortened = (this.getServers().length === 0 && !this.getSubscriberStatus());
    this.getData();
  },
  created() {
    /*
    if(!this.getSubscriberStatus() && this.getMemberships().length === 0) {
      this.restrictedGeneral = true;
    }
    */
  },
  data() {
    return {
      AccountStatus: AccountStatus,
      ready: false,
      error: false,
      data: null,
      restricted: false,
      restrictedGeneral: true,
      shortened: false
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <div class="row">
        <div class="col">
          <!-- Content row one -->
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <SteamPersona @overridePersona="overridePersona" :cftools_id="cftools_id" :restricted="restricted" />
                    </div>
                    <div class="col-lg-4">
                      <div class="h-100 mt-3" v-if="data.omega">
                        <div class="row ">
                          <div class="col">
                            <h5 class="text-uppercase d-inline">
                              {{$t('profile.omega.created_at')}}
                            </h5>
                            <h4 class="ml-2 d-inline" v-if="data.omega">
                              {{ $d(parseDate(data.omega.created_at), 'date', getDTLocale()) }}
                            </h4>
                          </div>
                        </div>
                        <hr>
                        <div class="row">
                          <div class="col">
                            <h5 class="text-uppercase d-inline">
                              {{$t('profile.omega.updated_at')}}
                            </h5>
                            <h4 class="ml-2 d-inline">
                              {{ $d(parseDate(data.omega.updated_at), 'date', getDTLocale()) }}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div class="h-100 mt-3" v-else>
                        <div class="row h-100">
                          <div class="col h-100 mt-4 text-center">
                            <h4 class="text-uppercase ">
                              {{$t('profile.omega.none')}}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
	    <div class="row">
		    <div class="col-lg-12">
			    <BattlEyeStatus :cftools_id="cftools_id" :restricted="restricted" />
		    </div>
	    </div>
	    <div class="row">
		    <div class="col-lg-12">
			    <EACStatus :cftools_id="cftools_id" :restricted="restricted" />
		    </div>
	    </div>
      <div class="row">
        <div class="col">
          <!-- Row two -->
          <template v-if="!shortened">
          <div class="row">
            <div class="col-lg-6">
              <ProfileActivities :cftools_id="cftools_id"/>
            </div>
            <div class="col-lg-6">
              <div class="card">
                <div class="card-body" v-if="!restrictedGeneral">
                  <h4 class="card-title mb-4">General</h4>
                  <div class="table-responsive mb-0">
                    <table class="table table-nowrap table-centered">
                      <tbody class="w-100">
                      <tr>
                        <td>
                          <h5 class="text-truncate font-size-14 m-0 text-dark">
                            {{ $t('profile.alternate.label') }}
                          </h5>
                        </td>
                        <td>
                          <div class="team">
                            <template v-if="data.linkages">
                              <ul>
                                <li v-for="link in data.linkages" :key="link.cftools_id">
                                  <router-link :to="{name: 'profile', params: {cftools_id: link.cftools_id}}" target="_blank">
                                    {{ link.cftools_id }}
                                  </router-link>
                                  <span class="badge badge-primary" v-if="link.watchProfileActive">
                                      <i class="fad fa-eye" />
                                    </span>
                                </li>
                              </ul>
                            </template>
                            <template v-else>
                                <span class="badge badge-info text-black">
                                  {{ $t('profile.alternate.none') }}
                                </span>
                            </template>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h5 class="text-truncate font-size-14 m-0 text-dark">
                            {{ $t('profile.aliases.label') }}
                          </h5>
                        </td>
                        <td>
                          <template v-if="privacyMode">
                              <span class="badge badge-warning text-black">
                                <i class="fad fa-user-secret"></i> {{$t('profile.privacy')}}
                              </span>
                          </template>
                          <template v-else>
                            <div class="team" v-if="data.omega">
                                <span v-for="(name_entry, index) in data.omega.aliases.slice(-5).reverse()" :key="'' + name_entry + '-' + index">
                                  <b v-if="index === 0" class="mr-1">{{ name_entry }}</b>
                                  <small v-else class="mr-1">{{name_entry}}</small>
                                </span>
                            </div>
                            <div class="team" v-else>
                              {{ $t('profile.omega.none') }}
                            </div>
                          </template>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h5 class="text-truncate font-size-14 m-0 text-dark">
                            {{ $t('profile.playtime.label') }}
                          </h5>
                        </td>
                        <td>
                          <template v-if="privacyMode">
                              <span class="badge badge-warning text-black">
                                <i class="fad fa-user-secret"></i> {{$t('profile.privacy')}}
                              </span>
                          </template>
                          <template v-else>
                            <div class="team" v-if="data.omega">
                              <time-ago :refresh="0" :datetime="timeTrick(data.omega.playtime)" :locale="getUILanguage()" />
                              ({{ data.omega.sessions }} {{ $t('profile.server.sessions') }})
                            </div>
                            <div class="team" v-else>
                              {{ $t('profile.omega.none') }}
                            </div>
                          </template>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="card-body" v-else>
                  <div class="blur-content-text">
                    <div>
                      <div class="text-center">
                        <i class="fas fa-lock font-size-24 text-primary"/>
                        <div class="text-white">
                          Unlock Player Intelligence with Basic or a higher plan
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="blur-content">
                    <h4 class="card-title mb-4">General</h4>
                    <div class="table-responsive mb-0">
                      <table class="table table-nowrap table-centered">
                        <tbody class="w-100">
                        <tr>
                          <td>
                            <h5 class="text-truncate font-size-14 m-0 text-dark">
                              {{ $t('profile.alternate.label') }}
                            </h5>
                          </td>
                          <td>
                            <div class="team">
                              <a href="#">Lorem ipsum</a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h5 class="text-truncate font-size-14 m-0 text-dark">
                              {{ $t('profile.aliases.label') }}
                            </h5>
                          </td>
                          <td>
                            <template v-if="privacyMode">
                              <span class="badge badge-warning text-black">
                                <i class="fad fa-user-secret"></i> {{$t('profile.privacy')}}
                              </span>
                            </template>
                            <template v-else>
                              Lorem ipsum dolor sit amet, consetetur sadipscing elitr
                            </template>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h5 class="text-truncate font-size-14 m-0 text-dark">
                              {{ $t('profile.playtime.label') }}
                            </h5>
                          </td>
                          <td>
                            <template v-if="privacyMode">
                              <span class="badge badge-warning text-black">
                                <i class="fad fa-user-secret"></i> {{$t('profile.privacy')}}
                              </span>
                            </template>
                            <template v-else>
                              Lorem ipsum dolor sit amet, consetetur sadipscing elitr
                            </template>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </template>
          <template v-else>
            <div class="col-lg-12">
              <ProfileActivities :cftools_id="cftools_id"/>
            </div>
          </template>
        </div>
      </div>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-lg-11">
          <!-- Content row one -->
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclFacebook :speed="1" primary="#FFFFFF" secondary="#bdbdbd"></VclFacebook>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="card">
                <div class="card-body">
                  <VclBulletList :speed="1" primary="#FFFFFF" secondary="#bdbdbd"></VclBulletList>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="card">
                <div class="card-body">
                  <VclList :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4 mb-2"></VclList>
                </div>
              </div>
            </div>
          </div>

        </div>

        <!-- <div class="row">
                <div class="col-lg-6">

                </div>
                <div class="col-lg-6">

                </div>
              </div> -->
      </div>
    </template>
  </div>
</template>
